import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

type OptionType = {
  label: string,
  value: any,
};

type FormInputProps = {
  name: string,
  control: any,
  label: string,
  options: OptionType[]
};

export const FormInputRadio = ({
  name,
  control,
  label,
  options
}: FormInputProps) => {
  const generateRadioOptions = () => {
    return options.map((singleOption) => (
      <FormControlLabel
        key={singleOption.value}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio />}
      />
    ));
  };

  return (
    <FormControl>
      <Typography>{label}</Typography>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({
          field: { onChange, value }, fieldState: { error }
        }) => (
          <div>
            <RadioGroup value={value} onChange={onChange} row>
              {generateRadioOptions()}
            </RadioGroup>
            {error ? <FormHelperText sx={{color: 'error.main'}}>*This is required</FormHelperText> : null}
          </div>
        )}
      />
    </FormControl>
  );
};
