import {Grid, Box, Typography} from '@mui/material';

export const BodySection3 = () => {
  return (
<Grid container>
  <Grid container direction="row"
    justifyContent="center"
    marginTop={3}
  >
    <Grid item>
      <Typography variant="h3" marginBottom={2} marginX={3}>
        Dial in with automation tools
      </Typography>
    </Grid>
  </Grid>
  <Grid container direction="row"
    justifyContent="center"
    marginTop={3}
    marginBottom={12}
    sx={{ml: {xs: 17, sm: 6, md: 10, lg: 20}}}
  >
    <Grid item xs={12} sm={4} md={3} xl={2}>
      <Box
      component="img"
      src="/images/hex_dollarsign.png"
    />
        <Typography variant="h5">Automatic payments</Typography>
    </Grid>
    <Grid item xs={12} sm={4} md={3} xl={2}>
      <Box
      component="img"
      src="/images/hexWrench.png"
    />
      <Typography variant="h5">Maintenance tracking</Typography>
    </Grid>
    <Grid item xs={12} sm={4} md={3} xl={2}>
      <Box
      component="img"
      src="/images/hexCalculator.png"
    />
      <Typography variant="h5">Accounting tools</Typography>
    </Grid>
    </Grid>
  </Grid>
  );
}
