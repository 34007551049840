import { useState } from "react";
import { useForm } from 'react-hook-form';
import { FormInputText } from './FormInputText';
import { Grid, Button, Typography, Box } from '@mui/material';
import axios from "axios";
import { FORMSPARK_FORM_ID, PRIMARY_COLOR } from '../Constants';
import ReactPixel from 'react-facebook-pixel';
import { FormInputMultilineText } from "./FormInputMultilineText";
import { FormInputRadio } from "./FormInputRadio";

type InputProps = {
  originName: string;
};

type ContactDetails = {
  email: string,
  name: string,
  message: string,
  personType: string
};

type ServiceMessage = {
  color: string;
  text: string;
};

const initialFormState = {
  email: "",
  name: "",
  message: "",
  personType: ""
};

export const ContactFormFull = ({originName} : InputProps) => {
  const formSparkUrl = `https://submit-form.com/${FORMSPARK_FORM_ID}`;

  const { handleSubmit, reset, control } = useForm<ContactDetails>({defaultValues: initialFormState});

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<ServiceMessage>();

  const onSubmit = async (data: ContactDetails) => {
    setSubmitting(true);
    await postSubmission(data);
    setSubmitting(false);
    ReactPixel.track('Lead');
  };

  const postSubmission = async (data: ContactDetails) => {
    var urlParams = new URLSearchParams(window.location.search);
    const payload = {
      ...data,
      originName: originName,
      cardId: urlParams.has('cid') ? urlParams.get("cid") : ""
    };

    try {
      await axios.post(formSparkUrl, payload);
      setMessage({
        color: PRIMARY_COLOR,
        text: "Thanks, someone will be in touch shortly!",
      });
      reset();
    } catch (error) {
      setMessage({
        color: "red",
        text: "Sorry, there was a problem. Please try again or email us at info@dials.com",
      });
    }
  };

  return (
    <Grid container direction="row"
      justifyContent="center"
      marginTop={2}
      marginBottom={4}
      maxWidth={340}
      rowGap={2}
    >
      <FormInputText name="name" control={control} label="Name"/>
      <FormInputText name="email" control={control} label="Email Address"/>
      <FormInputMultilineText name="message" control={control} label="Message" isOptional={true}/>
      <FormInputRadio name="personType" label="I am a" control={control}
        options={[
          {label: "HOA board member", value: "HOA Board Member"},
          {label: "Homeowner", value: "Homeowner"},
          {label: "Association manager", value: "Association manager"},
          {label: "Property developer", value: "Property Developer"},
          {label: "Other", value: "Other"}
        ]}
      />
        <Button disabled={submitting} onClick={handleSubmit(onSubmit)} variant={"contained"}
         sx={{width: '400px', fontWeight:'bold', backgroundColor:'secondary.dark', fontSize: 15}}>
          {" "}
          {submitting ? "..." : "Submit"}
        </Button>
        {message &&
        (<Typography margin={2} padding={2} variant="subtitle1" color="white" style={{backgroundColor: message.color, borderRadius: 5}}>
            {message.text}
        </Typography>)
        }
    </Grid>
  );
};
