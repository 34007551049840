

import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

interface FormInputProps {
  name: string;
  control: any;
  label: string;
  isOptional: boolean;
  maxLength?: number;
}

export const FormInputMultilineText = ({name, control, label, isOptional, maxLength}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: !isOptional }}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <TextField
          multiline
          rows={4}
          variant="outlined"
          size="small"
          onChange={onChange}
          value={value}
          fullWidth
          sx={{maxWidth: 500}}
          label={label}
          error={!!error}
          inputProps={{ maxLength: maxLength ?? 100}}
          helperText={error ? "*This is required" : null}
        />
      )}
    />
  );
};
