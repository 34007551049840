import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { NavBarTop } from './components/NavBarTop';
import { NavBarBottom } from './components/NavBarBottom';
import { Home } from './pages/Home';
import { Contact } from './pages/Contact';
import { Privacy } from './pages/Privacy';
import { CardCampaignCd } from './pages/campaigns/cards/CardCampaignCd';
import { CardCampaignDriving } from './pages/campaigns/cards/CardCampaignDriving';
import { PRIMARY_COLOR, FB_PIXEL_ID } from './Constants';
import ReactPixel from 'react-facebook-pixel';
import { About } from './pages/About';
import { Box } from '@mui/material';


const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      light: '#3B3B3B',
    },
    secondary: {
      main: '#FFF',
      light: '#F1F1F1',
      dark: '#FF6464',

    },

  },
  typography: {
    fontFamily: ['"Helvetica"', 'sans-serif'].join(','),
    button: {
      textTransform: 'none'
    },
   }
});

export const App = () => {
  ReactPixel.init(FB_PIXEL_ID);
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <NavBarTop/>
        <Box mt={7}>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/privacy" element={<Privacy/>} />
            <Route path="/about" element={<About/>} />

            {/* Marketing campaigns */}
            <Route path="/c/driving/index.html" element={<CardCampaignDriving />} />
            <Route path="/c/cd" element={<CardCampaignCd/>} />

            <Route path="*" element={<Home/>} />
          </Routes>
        </Box>
        <NavBarBottom/>
      </BrowserRouter>
    </ThemeProvider>
  );
};
