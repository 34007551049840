import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';

interface FormInputProps {
  name: string;
  control: any;
  label: string;
  isOptional?: boolean;
  setValue?: any;
}

const validateInput = (value: string) => {
  if(value.trim().length === 0) {
    return "This can't be empty"
  }
}

export const FormInputText = ({name, control, label, isOptional}: FormInputProps) => {
  const isRequired = isOptional ? !isOptional : true;
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired ? '*This is required' : false,
        validate: isRequired ? (value) => validateInput(value) : undefined
      }}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <TextField
          variant="outlined"
          size="small"
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          error={!!error}
          helperText={error ? error.message : null}
        />
      )}
    />
  );
};
