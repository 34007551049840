import { Grid, Button, Link, Box, Typography } from '@mui/material';
import { APP_LINK } from '../Constants';
export const FooterSection = () => {

return (
  <Box
    display="flex"
      sx={{backgroundColor:"primary.main",   justifyContent:"center"}}
  >
    <Grid container direction="row"
      justifyContent="center"
      marginBottom={4}
      sx={{
        backgroundColor:"primary.main",
        p: { xs: 1},
      }}
    >
      <Grid item xs={12} sm={1} md={1} lg={2} xl={6}  color={'white'}
        sx={{
          position: 'relative',
          p: { xs: 1},
          maxWidth: { xs: 350, md: 300, lg: 400, xl: 280 },
        }}
      >
        <Typography component="div" variant="h6" marginY={2}>Reach out</Typography>
        <Typography>1111 Broadway FL 3</Typography>
        <Typography>Oakland, CA 94607</Typography>
        <Typography>+1 (707) 653-6039</Typography>
        <Typography><Link href="mailto:info@dials.com" underline="hover" color="white">info@dials.com</Link></Typography>
      </Grid>
      <Grid item xs={12} sm={1}  md={1} lg={2} xl={6}  color={'white'}
        sx={{
          position: 'relative',
          p: { xs: 1},
          maxWidth: { xs: 350, md: 300, lg: 400, xl: 230 },
        }}
      >
        <Typography component="div" variant="h6" marginY={2}>Dials support</Typography>
        <Typography><Link href="/Contact" underline="hover" color="white" >Contact</Link></Typography>
        <Typography><Link href="/Privacy" underline="hover" color="white" >Privacy policy</Link></Typography>
      </Grid>
      <Grid item xs={12} sm={1}  md={1} lg={2} xl={6} marginX={2}  color={'white'}
        sx={{
          position: 'relative',
          p: { xs: 1},
          maxWidth: { xs: 350, md: 300, lg: 400, xl: 190},
        }}
      >
        <Typography component="div" variant="h6" marginY={2}>Our content</Typography>
        <Typography><Link href="https://me.dials.com/" underline="hover" color="white" >Blog</Link></Typography>
      </Grid>
      <Grid item xs={12} sm={1} md={2} lg={2} xl={6} margin={2} color={'white'}
        sx={{
          position: 'relative',
          p: { xs: 1},
          maxWidth: { xs: 350, md: 300, lg: 400, xl: 400 },
        }}
      >
        <a href="https://me.dials.com/">
          <Box
          component="img"
          sx={{
            borderRadius: 3
          }}
            src="/images/dialsBloglogo.png"
          />
        </a>
        <Typography variant='subtitle1' color={'white'} component='div' align='justify'>Interested in learning more? Check out our blog with tips and tricks for both homeowners and HOA board members.</Typography>
    </Grid>
    </Grid>
  </Box>
  );
};
