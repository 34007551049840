import { Box, Container, Typography } from "@mui/material";
import { ContactFormFull } from "../../../components/ContactFormFull";

export const CardCampaignDriving = () => {
  return (
    <Container>
      <Box
        mt={5}
        component="img"
        sx={{
          width: 800,
          maxWidth: {xs: 300, md: 600, xl: 800 },
        }}
        src="/images/card-campaigns/driving-front.png"
      />
      <Typography variant="h6" margin={1}>
        We set customers up one at a time, to ensure everything goes well.
      </Typography>

      <ContactFormFull originName="card-driving"/>
    </Container>
  );
};
