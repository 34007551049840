import React from 'react';
import { Typography } from '@mui/material';
import { Card, CardMedia, CardContent } from '@mui/material';

type InputProps = {
  media: string;
  headline: string;
  content: string;
};

export const FeatureCard = ({ media, headline, content } : InputProps) => {

  return (
    <Card sx={{ width: 230, minWidth: 160, margin: 3, alignContent:'center'
    }}>
      <CardMedia
        component="img"
        height="100"
        src={media}
      />
      <CardContent>
        <Typography variant="h6" style={{ fontWeight: 'bold', whiteSpace: "pre-line" }}>
          {headline}
        </Typography>
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}
