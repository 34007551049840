import { BodySection1 } from '../components/BodySection1'
import { BodySection2 } from '../components/BodySection2'
import { BodySection3 } from '../components/BodySection3'
import { HeroSection } from '../components/HeroSection'
import { FeaturesScroll } from '../components/FeaturesScroll'
import { ContactSection } from '../components/ContactSection'
import { FooterSection } from '../components/FooterSection'
import { Container } from '@mui/material'

export const Home = () => {
  return(
    <>
      <HeroSection/>
      <BodySection3/>
      <BodySection1/>
      <BodySection2/>
      <FeaturesScroll/>
      <ContactSection/>
      <FooterSection/>
    </>
  );
};
