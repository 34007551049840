import {Grid, Box, Typography} from '@mui/material';

export const BodySection1 = () => {
  return (
    <Grid container direction="row"
      justifyContent="center"
      sx={{backgroundColor:"secondary.light",
      p: { xs: 3},
    }}
    >
      <Grid item xs={12} sm={12} md={5}
        sx={{
        maxWidth: { xs: 350, md: 300, lg: 400, xl: 650},
        mt: { xs: 1, md: 7}
      }}
      >
        <Typography component="div" variant="h4" marginBottom={2}  >
          Serve your community more efficiently
        </Typography>
        <Typography variant='subtitle1' color='text.secondary' component='div' align='justify'>
          We know running an HOA is a complex job often done by volunteers. Our HOA platform is designed to free up your busy schedule. Whether its collections, expenses or managing funds, we'll help streamline this process for you and your association.
        </Typography>
      </Grid>
      <Grid item>
        <Box
          component="img"
          padding={3}
          sx={{
            maxWidth: {xs: 350, xl: 650 },
          }}
            src="/images/assessmentImage.png"
        />
      </Grid>
    </Grid>
  );
}
