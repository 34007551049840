import { Typography, Grid, Box, Paper } from '@mui/material';
import { FooterSection } from '../components/FooterSection'
import { Container } from '@mui/material'


export const About = () => {


  return (  /*This is the container that holds everything */
    <Container maxWidth={false} >
      <Grid container direction="row" justifyContent="-center" sx={{backgroundColor:"primary.main",}}>
        <Grid container direction="row" /* holds the "aobut us" header text */ justifyContent="center">
            <Grid item xs={12} sm={8} md={5} lg={12} xl={12}
              sx={{
                maxWidth: { xs: 350, md: 300, lg: 1150, xl: 1080 },
              }}
            >
              <Box
                sx={{
                  my: 8,
                }}
              >
                <Typography component="h1" variant="h3" color="white">{'About us'}</Typography>
              </Box>
            </Grid>
        </Grid>
        <Grid container direction="row" xs={12} sm={8} md={5} lg={12} xl={12} justifyContent="center"sx={{backgroundColor:"white",}}>
          <Grid item xs={12} sm={8} md={5} lg={12} xl={12}  color={'white'} /* Grid that holds the contact us paragraph text */ sx={{paddingTop: { xs: 2, sm: 2, med: 2, lg: 2, xl: 5},maxWidth: { xs: 350, md: 300, lg: 1150, xl: 1080 },}}>
            <Typography variant="h6" color="black" paragraph>{'We’re a tightly-knit team with a variety of backgrounds from engineering to design. Our goal is to eliminate the mundane tasks of running an HOA.'}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" xs={12} sm={8} md={5} lg={12} xl={12} /*Container that holds the three columns profile stuff */ justifyContent="center" sx={{backgroundColor:"white", paddingBottom: { xs: 10}, paddingTop: { xs: 5},}}>
          <Grid item xs={12} sm={8} md={5} lg={12} xl={12}  color={'white'} /*  David's profile*/ textAlign="center" sx={{ paddingTop: { xs: 2, sm: 2, med: 2, lg: 2, xl: 1}, maxWidth: { xs: 350, md: 300, lg: 1150, xl: 360 },}}>
            <Box component="img" src="/images/profilepicDavid.png" sx={{ paddingBottom: { xs: 2, sm: 2, med: 2, lg: 2, xl: 3},}}/>
              <Box sx={{paddingBottom: { xs: 2, sm: 2, med: 2, lg: 2, xl: 1},}}>
                <Typography variant="h5" color="black">David Albrecht</Typography>
              </Box>
            <Box sx={{paddingBottom: { xs: 2, sm: 2, med: 2, lg: 2, xl: 1},}}>
              <Typography variant="h6" color="primary.main">CEO / Founder</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" color="black">Second time founder, former software engineer at Starsky Robotics with a focus on truck stystems engineering. Former HOA treasurer of 300+ units. </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={5} lg={12} xl={12}  color={'white'} /*  Aishanou's profile*/ textAlign="center"sx={{paddingTop: { xs: 2, sm: 2, med: 2, lg: 2, xl: 1}, maxWidth: { xs: 350, md: 300, lg: 1150, xl: 360 },}}>
            <Box component="img" src="/images/profilepicAishanou.png" sx={{paddingBottom: { xs: 2, sm: 2, med: 2, lg: 2, xl: 3},}}/>
              <Box sx={{paddingBottom: { xs: 2, sm: 2, med: 2, lg: 2, xl: 1},}}>
                <Typography variant="h5" color="black">Aishanou Rait</Typography>
            </Box>
            <Box sx={{paddingBottom: { xs: 2, sm: 2, med: 2, lg: 2, xl: 1},}}>
              <Typography variant="h6" color="primary.main">CTO / Founder</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" color="black">Vision and perception engineer with a focus on robotics. Worked at FarmWise and Starsky robotics leading product development.</Typography>
            </Box>
          </Grid>
            <Grid item xs={12} sm={8} md={5} lg={12} xl={12}  color={'white'} /*  Matt's profile*/ textAlign="center" sx={{paddingTop: { xs: 2, sm: 2, med: 2, lg: 2, xl: 1}, maxWidth: { xs: 350, md: 300, lg: 1150, xl: 360 },}}>
              <Box component="img" src="/images/profilepicMatt.png" sx={{ paddingBottom: { xs: 2, sm: 2, med: 2, lg: 2, xl: 3},}}/>
                <Box sx={{ paddingBottom: { xs: 2, sm: 2, med: 2, lg: 2, xl: 1}, }}>
                  <Typography variant="h5" color="black">Matthew Vicini</Typography>
                </Box>
              <Box sx={{paddingBottom: { xs: 2, sm: 2, med: 2, lg: 2, xl: 1},}}>
                <Typography variant="h6" color="primary.main">Product designer</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" color="black">Designer with a background in UI/UX for video games. Has worked on products for Disney and popular Youtube channels.</Typography>
              </Box>
            </Grid>
          </Grid>
     </Grid>
    <FooterSection/>
  </Container>
  );
};
