import { Grid, Typography } from '@mui/material';
import { ContactFormFull } from './ContactFormFull'

export const ContactSection = () => {
  return (
  <section id="onboard">
    <Grid container direction="row"
      justifyContent="center"
      spacing={5}
      marginTop={1}
      marginBottom={4}
      sx={{
      p: { xs: 3},
      }}
    >
      <Grid item xs={0} md={0}>
        <Typography variant="h3" marginTop={3}>Get onboarded now!</Typography>
        <Grid item xs={12} md={10} marginX={0} color={'black'} paddingRight={10}
          sx={{ maxWidth: { xs: 500, md: 300, lg: 400, xl: 780 },}}>
        <Typography variant="h6" marginTop={3}>
          We are excited to have you here.
          Please fill out the form and we'll contact you soon.
        </Typography>
        </Grid>
      </Grid>
      <Grid item xs={0} md={0} marginX={2}>
        <ContactFormFull originName="Home Page full form"/>
      </Grid>
    </Grid>
  </section>
  );
};
