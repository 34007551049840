import React from 'react';
import { Tabs, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { FeatureCard } from './FeatureCard';



const features = [

  { label: "Feature5", media: '/images/compliance-reminder.jpg', headline: 'Davis-Stirling compliance', content: 'Get timely reminders to stay on top of legal compliance.' },
  { label: "Feature6", media: '/images/reserves.jpg', headline: 'Reserves building', content: 'Easily plan for and track the health of your reserves.' },
  { label: "Feature4", media: '/images/record-keeping.jpg', headline: 'Record keeping', content: 'Board turnover isn’t a problem because the data is tied to the building not a person.' },
  { label: "Feature7", media: '/images/electric-vehicle.jpg', headline: 'Budgeting', content: 'Create accurate budgets and easily project what your HOA community needs in the future.' },
]

export const FeaturesScroll = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
   <div>
    <Box display='flex' justifyContent='center' alignItems='center'
        paddingTop={3}
        sx={{backgroundColor:"secondary.light"
      }}
      >
        <Typography component="div" variant="h3" margin={3}  justifyContent='center'>
          The Dials advantage
        </Typography>
      </Box>
      <Box display='flex' justifyContent='center' alignItems='center' sx={{bgcolor: 'secondary.light'}}
        paddingBottom={10}
        >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs"
        >
          {features.map((feature) => (
            <FeatureCard key={feature.label} media={feature.media} headline={feature.headline} content={feature.content} />
          ))}
        </Tabs>
      </Box>
    </div>
  );
}
