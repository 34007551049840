import {Grid, Box, Typography} from '@mui/material';

export const BodySection2 = () => {
  return (
    <Grid container direction="row"
      justifyContent="center"
      spacing={5}
      marginTop={1}
      marginBottom={4}
      sx={{
        p: { xs: 3},
     }}
    >
      <Grid item xs={12} md={5} marginX={2} display={{ xs: "none", md: "block" }} color='white'
        sx={{
        p: { xs: 3},
        maxWidth: { xs: 350, md: 300, lg: 400, xl: 550 },
        }}
      >
        <Box
          component="img"
          sx={{
            maxWidth: { xs: 350, md: 410, lg: 480, xl: 500 },
            borderRadius: 3
          }}
          src="/images/websitepeoplegraphic.png"
        />
      </Grid>
      <Grid item xs={12} md={5}
        sx={{
        marginTop: { xs: 0, md: 5},
        p: { xs: 3},
        marginLeft: {xs: 0, md: 20, lg:20, xl: 0 },
        maxWidth: { xs: 350, md: 300, lg: 400, xl: 820 },
        }}
      >
        <Typography component="div" variant="h4" marginBottom={2}>
          Made by a treasurer, built for HOA members
        </Typography>
        <Typography variant='subtitle1' color='text.secondary' component='div' align='justify'>
          Still submitting paper checks to your HOA? Reserves poorly managed or insufficiently funded? Repairs taking forever? We feel your pain. Dials is paving the way to eliminate poorly managed reserves, manual check processing, and old methods of bookkeeping among HOA members.
        </Typography>
      </Grid>
      <Grid item xs={12} md={5} marginX={2} display={{ xs: "block", md: "none" }} color='white'
        sx={{
        p: { xs: 3},
        maxWidth: { xs: 350, md: 300, lg: 400, xl: 550 },
        }}
      >
        <Box
          component="img"
          sx={{
            maxWidth: { xs: 350, md: 410, lg: 480, xl: 500 },
            borderRadius: 3
          }}
          src="/images/websitepeoplegraphic.png"
        />
      </Grid>
    </Grid>
  );
}
