import { Typography, Link, Grid, Box } from '@mui/material';
import { ContactFormFull } from '../components/ContactFormFull';
import { FooterSection } from '../components/FooterSection'
import { Container } from '@mui/material';

export const Contact = () => {


  return (
    <>
      <Grid container direction="row"   /*Horizontal turquoise block that*/ justifyContent="center" sx={{backgroundColor:"primary.main",}}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h3" color="white" sx={{my: 8, ml: {xs: 2, sm: 4, md: 12}}}>
            Contact us
          </Typography>
        </Grid>
        <Grid container direction="row"
          /*Container that holds  the "contact us paragraph text (left side) and  contact form (right side) */
          justifyContent="center" sx={{backgroundColor:"white"}}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} color="white" m={2} sx={{ml: {xs: 2, sm: 4}}} /* Grid that holds the contact us paragraph text */>
            <Typography variant="h6" color="black" paragraph>
              Questions or concerns? Please fill out the form or email us at <Link href="mailto:info@dials.com" underline="hover">
               info@dials.com</Link>.
            </Typography>
          </Grid>
          <Grid className="contactPageFormStyle" item xs={12} sm={12} md={4} lg={3}
            /* Grid that holds the sign up form */
            sx={{backgroundColor: 'white',
              maxWidth: 340,
              justifyContent: "center",
              p: 3,
              display: "flex",
              mt: {md: -14, xs: 0}

            }}>
            <ContactFormFull originName="Contact Us page"/>
          </Grid>
        </Grid>
      </Grid>
      <FooterSection/>
    </>
  );
};
