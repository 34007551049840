
import { Typography, Grid, Box, Button } from '@mui/material';
import ReactPixel from 'react-facebook-pixel';
import { APP_LINK } from '../Constants';
import { HashLink } from 'react-router-hash-link';

export const HeroSection = () => {
  ReactPixel.pageView();
  ReactPixel.fbq('track', 'PageView');
  return (
    <Grid container direction="row"
      display="flex"
      justifyContent="center"
      sx={{ backgroundColor:"primary.main",
        paddingTop: { xs: 4},
        paddingBottom: { xs: 5},
      }}
    >
      <Grid item xs={12} sm={9} md={5.5} lg={4} xl={4} color="white"
        sx={{
          position: 'relative',
          px: { xs: 3}
        }}
      >
        <Typography variant="h2">The master checklist for HOAs</Typography>
        <Typography variant="h5" color="inherit" paragraph>
          Reinventing homeowners association management through task tracking and automation.
        </Typography>
        <Box display='flex' flexDirection='column' width={320} gap={1}>
          <Button variant="contained" sx={{fontWeight:'bold', backgroundColor:'secondary.dark'}} component={HashLink} to="#onboard" smooth>
            Get help onboarding
          </Button>
          <Button variant="outlined" sx={{fontWeight:'bold', backgroundColor: "secondary.main", ':hover': {
            bgcolor: "#AAAAAA",
          }}} href={APP_LINK}>
            I'll do it myself
          </Button>
        </Box>
      </Grid>
      <Grid item
          sx={{
          p: { xs: 2},
          }}>
        <Box
          component="img"
            sx={{
              borderRadius: 2,
            }}
            src="/images/websiteheadergraphic.png"
        />
      </Grid>
    </Grid>
  );
}
