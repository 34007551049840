import React, { useState } from 'react';
import { AppBar, Toolbar, Theme, Box, Button } from '@mui/material' ;
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Menu as MenuIcon } from '@mui/icons-material';
import { APP_LINK } from '../Constants';


const BLOG_LINK = "https://me.dials.com";
const CAREERS_LINK = "https://dials.breezy.hr/?";

const useStyles = makeStyles((theme: Theme) => ({
  navlinks: {
    justifyContent: "flex-end"
  },
 logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "20px",
    marginInline: theme.spacing(2),
    "&:hover": {
      color: "grey",
    },
  },
}));

export const NavBarTop = () => {
  const classes = useStyles();

  const pages = [
    { label: "Contact", component:  <Link to="/contact" className={classes.link}>Contact</Link> },
    { label: "Blog", component: <a className={classes.link} href={BLOG_LINK}>Blog</a>},
    //{ label: "Careers", component: <a className={classes.link} href={CAREERS_LINK}>Careers</a> },
    //{ label: "About", component: <a className={classes.link} href="/About">About</a> },
  ]

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed" color="secondary">
      <Toolbar>
        <Link to="/" className={classes.logo}>
          <img alt="logo" src="/images/dials_logo_small.png" height={38}/>
        </Link>
        <Box sx={{display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="options menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="primary"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                {page.component}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' }}}>
          {pages.map((page) => (
            <Button
              key={page.label}
              onClick={handleCloseNavMenu}
              sx={{color: 'black', display: 'block', textTransform: 'none'}}
            >
              {page.component}
            </Button>
          ))}
        </Box>
        <Box display='flex' flexDirection='row' gap={1}>
          <Button variant="contained"  sx={{fontWeight:'bold' }} href={APP_LINK}>
            Log in
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
