import { Typography, Link, Accordion, AccordionSummary, AccordionDetails, Grid, Box, Paper } from '@mui/material';
import { FooterSection } from '../components/FooterSection'
import { Container } from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'



export const Privacy = () => {

  return (  /*This is the container that holds everything */
   <Container maxWidth={false} >
    <Grid container direction="row" justifyContent="-center"sx={{backgroundColor:"primary.main",}}>
      <Grid container direction="row" /* holds the "aobut us" header text */justifyContent="center">
        <Grid item xs={12} sm={8} md={5} lg={12} xl={12}sx={{maxWidth: { xs: 350, md: 300, lg: 1150, xl: 1080 },}}>
         <Box sx={{my: 8,}}>
           <Typography component="h1" variant="h3" color="white">{'Privacy policy'}</Typography>
         </Box>
        </Grid>
      </Grid>
      <Grid container direction="row" xs={12} sm={8} md={5} lg={12} xl={12} justifyContent="center"sx={{backgroundColor:"white",}}>
        <Grid item xs={12} sm={8} md={5} lg={12} xl={12}  color={'white'} /* Grid that holds the contact us paragraph text */sx={{paddingTop: { xs: 2, sm: 2, med: 2, lg: 2, xl: 5},maxWidth: { xs: 350, md: 300, lg: 1150, xl: 1080 },}}>
          <Typography variant="h6" color="black" paragraph>Thanks for choosing Truckers Inc. and Dials. Here you'll find information about how our privacy policy governs the collection, safeguard, and diclosure of information provided by you upon the use of our services. </Typography>
          <Typography variant='h6'  component='div' color="black">If you have any questions about this privacy policy, please <Link href="mailto:support@dials.com" underline="hover">contact us.</Link></Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" xs={12} sm={8} md={5} lg={12} xl={12} justifyContent="center"sx={{backgroundColor:"white",paddingBottom: { xs: 10},paddingTop: { xs: 5},}}>
        <Grid item xs={12} sm={8} md={5} lg={12} xl={12}  color={'white'} textAlign="center"sx={{paddingTop: { xs: 2, sm: 2, med: 2, lg: 2, xl: 1},maxWidth: { xs: 350, md: 300, lg: 1150, xl: 1080 }}}>
          <Grid container direction="row" textAlign="left">
            <Accordion>
              <AccordionSummary id='panel1-header' /* Usage of service */ aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" >Usage of service</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>We use your data to provide and improve our services. By using our services, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this privacy policy, the terms used in this privacy policy have the same meanings as in our terms and conditions. Our terms and conditions (“terms”) govern all use of our services and together with the privacy policy constitutes your agreement with us.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary id='panel1-header' /* Services */aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" >Services</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Truckers Inc. (“us”, “we”, or “our”) operates the websites accessible at <a href="https://www.dials.com/">https://dials.com</a> and <a href=" https://app.dials.com/"> https://app.dials.com/</a> ("the websites") and associated services, known collectively as "services".</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary id='panel1-header' /* Definitions */aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" >Definitions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography><b>Personal data</b> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</Typography>
                <Typography> <b>Usage data</b> is data collected either automatically or generated by the use of our services from the service infrastructure itself (for example, the duration of a page visit).</Typography>
                <Typography><b>Cookies</b> are small files stored on your device (computer or mobile device).</Typography>
                <Typography><b>Data controller</b> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this privacy policy, we are a data controller of your data.</Typography>
                <Typography><b>Data processors</b> (or services proviers) means any natural or legal person who processes the data on behalf of the data controller. We may use the services of various services providers in order to process your data more effectively.</Typography>
                <Typography><b>Data subject</b> is any living individual who is the subject of personal data.</Typography>
                <Typography><b>The user</b> is the individual using our services. The user corresponds to the data subject, who is the subject of personal data.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary id='panel1-header'  /* Personal data */aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Personal data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>While using our services, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“personal data”). Personally identifiable information may include, but is not limited to:</Typography>
                  <ul>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Address</li>
                    <li>State</li>
                    <li>Province</li>
                    <li>Zip/postal code</li>
                    <li>City, cookies, and usage data</li>
                    <li>Location information</li>
                    <li>Financial and payment information</li>
                    <li>We may use your personal data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by emailing us at <a href="mailto:support@dials.com">support@dials.com.</a></li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary id='panel1-header'  /* Usage data*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" >Usage data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>We may also collect information that your browser sends whenever you visit our services or when you access services by or through a mobile device (“usage data”). This usage data may include information such as your computer's internet protocol address, browser type, browser version, the pages of our services that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data. When you access services with a mobile device, this usage data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile internet browser you use, unique device identifiers and other diagnostic data.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary id='panel1-header'  /* Tracking Cookies Data*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" >Tracking cookies data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>We use cookies and similar tracking technologies to track the activity on our services. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our services. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our services.</Typography>
                <Typography> Examples of Cookies we use:</Typography>
                  <ul>
                      <li><b>Session cookies:</b> We use session cookies to operate our services.</li>
                      <li><b>Preference cookies:</b> We use preference cookies to remember your preferences and various settings.</li>
                      <li><b>Security cookies:</b> We use security cookies for security purposes.</li>
                      <li><b>Advertising cookies:</b> Advertising cookies are used to serve you with advertisements that may be relevant to you and your interests.</li>
                  </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary id='panel1-header'  /* Use of Data*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" >Use of data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Truckers Inc. uses the collected data for various purposes:</Typography>
                  <ul>
                    <li>To provide and maintain our service</li>
                    <li>To notify you about changes to our service</li>
                    <li>To allow you to participate in interactive features of our services when you choose to do so</li>
                    <li>To provide customer support</li>
                    <li>To gather analysis or valuable information so that we can improve our service</li>
                    <li>To monitor the usage of our service</li>
                    <li>To detect, prevent, and address technical issues</li>
                    <li>To fulfill any other purpose for which you provide it</li>
                    <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection</li>
                    <li>To provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.</li>
                    <li>To provide you with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</li>
                    <li>To in any other way we may describe when you provide the information</li>
                    <li>For any other purpose with your consent.</li>
                  </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary id='panel1-header'  /* Retention of Data*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" >Retention of data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>We will retain your personal data only for as long as is necessary for the purposes set out in this privacy policy. We will retain and use your personal data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. We will also retain usage data for internal analysis purposes. Usage data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our services, or we are legally obligated to retain this data for longer time periods.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary id='panel1-header'  /* Transfer of Data*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" >Transfer of data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Your information, including personal data, may be transferred to—and maintained on—computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. If you are located outside United States and choose to provide information to us, please note that we transfer the data, including personal data, to United States and process it there. Your consent to this privacy policy followed by your submission of such information represents your agreement to that transfer. Truckers Inc. will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy and no transfer of your personal data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary id='panel1-header'  /* Disclosure of Data*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" >Disclosure of data</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>We may disclose personal information that we collect, or you provide: </Typography>
                  <ul>
                    <li> Disclosure for law enforcement: Under certain circumstances, we may be required to disclose your personal data if required to do so by law or in response to valid requests by public authorities.</li>
                    <li> Business Transaction: If we or our subsidiaries are involved in a merger, acquisition or asset sale, your personal data may be transferred.</li>
                    <li> Other cases. We may disclose your information also:</li>
                      <ul>
                        <li> To our subsidiaries and affiliates</li>
                        <li> To contractors, services providers, and other third parties we use to support our business</li>
                        <li> To fulfill the purpose for which you provide it</li>
                        <li> For the purpose of including your companys logo on our website</li>
                        <li> For any other purpose disclosed by us when you provide the information</li>
                        <li> With your consent in any other cases</li>
                        <li> If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the company, our customers, or others. </li>
                      </ul>
                  </ul>
             </AccordionDetails>
           </Accordion>
          <Accordion>
            <AccordionSummary id='panel1-header'  /* Security of Data*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" >Security of data</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>The security of your data is important to us but remember that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.</Typography>
            </AccordionDetails>
           </Accordion>
          <Accordion>
            <AccordionSummary id='panel1-header'  /* Your Data Protection Rights Under General Data Protection Regulation (GDPR)*/aria-controls='panel1-content' expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" >Your data protection rights under general data protection regulation (GDPR)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR; further information is available at <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">https://eur-lex.europa.eu/eli/reg/2016/679/oj.</a> We aim to take reasonable steps to allow you to correct amend, delete, or limit the use of your personal data. If you wish to be informed what personal data we hold about you and if you want it to be removed from our systems, please email us at <a href="mailto:support@dials.com">support@dials.com.</a> In certain circumstances, you have the following data protection rights:</Typography>
              <ul>
                <li>The right to access, update or to delete the information we have on you</li>
                <li>The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete</li>
                <li>The right to object. You have the right to object to our processing of your personal data</li>
                <li>The right of restriction. You have the right to request that we restrict the processing of your personal information</li>
                <li>The right to data portability. You have the right to be provided with a copy of your personal data in a structured, machine-readable, and commonly used format</li>
                <li>The right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information</li>
              </ul>
              <Typography> Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide services without some necessary data. You have the right to complain to a data protection authority about our collection and use of your personal data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</Typography>
           </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary id='panel1-header'  /* Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" >Your data protection rights under the California Privacy Protection Act (CalOPPA)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography> CalOPPA is the first state law in the United States to require commercial websites and online services to post a privacy policy. The laws reach stretches well beyond California to require a person or company in the United States (and conceivably the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. See more at <a href = "https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/.</a></Typography>
             <Typography>According to CalOPPA we agree to the following:</Typography>
              <ul>
                  <li>Users can visit our site anonymously</li>
                  <li>Our privacy lolicy link includes the word “privacy”, and can easily be found on the page specified above on the home page of our website</li>
                  <li>Users will be notified of any privacy policy changes on our privacy policy page </li>
                  <li>Users are able to change their personal information by emailing us at <a href="mailto:support@dials.com">support@dials.com.</a></li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary id='panel1-header'  /* Our Policy on “Do Not Track” Signals*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6"  >Our policy on “Do Not Track” signals</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked. You can enable or disable Do Not Track by visiting the preferences or settings page of your web browser.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary id='panel1-header'  /* Third-party service providers*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" >Third-party service providers</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>We may employ third party companies and individuals to facilitate our services (“services providers”), provide services on our behalf, perform service-related services or assist us in analysing how our services is used. These third parties have access to your personal data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose. These providers may include, but are not limited to:</Typography>
                <ul>
                  <li><b>Google Analytics:</b> Google Analytics is a web analytics services offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our services. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network. For more information on the privacy practices of Google, please visit the Google Privacy Terms page. We also encourage you to review Google's policy for safeguarding your data</li>
                  <li><b>GitHub:</b> GitHub is provided by GitHub, Inc. GitHub is a development platform to host and review code, manage projects, and build software. For more information on what data GitHub collects for what purpose and how the protection of the data is ensured, please visit GitHub's privacy policy page.</li>
                  <li><b>CircleCI:</b> CircleCI is provided by Circle Internet Services, Inc. CircleCI is a development tool for continuous integration, a development practice which is being used by software teams allowing them to build, test, and deploy applications easier and quicker on multiple platforms. For more information on what data Circle CI collects for what purpose and how the protection of the data is ensured, please visit Circle CIPrivacy policy page.</li>
                  <li><b>Stripe:</b> We use Stripe for payment processing. As we do not collect or store payment card information, we are not in scope for PCI-DSS compliance. Stripe's privacy policy provides more details.</li>
                </ul>
           </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary id='panel1-header'  /* Children's Privacy*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" >Children's privacy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Our services are not intended for use by children under the age of 18 (“child” or “children”). We do not knowingly collect personally identifiable information from children under 18. If you become aware that a child has provided us with personal data, please contact us. If we become aware that we have collected personal data from children without verification of parental consent, we take steps to remove that information from our servers.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary id='panel1-header'  /* Changes to This Privacy Policy*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" >Changes to this privacy policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. We will let you know via email and/or a prominent notice on our services, prior to the change becoming effective and update “effective date” at the top of this privacy policy. You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary id='panel1-header'  /* Changes to This Privacy Policy*/aria-controls='panel1-content'expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" >Contact us</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>If you have any questions about this privacy policy, please contact us by email at <a href="mailto:support@dials.com">support@dials.com</a> and we'll be sure to get back with you as soon as possible. </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  <FooterSection/>
  </Container>
  );
};
